<template>
  <div>
    <Edit
      :item="item"
      :close="closeEdit"
      :create="create"
      :update="update"
    />
    <Loading :isActive="loading"></Loading>
    <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px;">
      <div class="form-group" style="display: flex; align-items: center">
        <datetime
          input-class="form-control"
          v-model="filtros.data"
          type="date"
          placeholder="Vigentes em"
          :phrases="{ok: 'Confirmar', cancel: 'Fechar'}"
          value-zone="local"
        />
        <input
          class="form-control ml-1"
          type="text"
          v-model="filtros.termo"
          placeholder="Descrição"
          v-on:keyup.enter="loadRegras"
        />
        <button class="button button-primary ml-1" v-on:click="loadRegras">
          Buscar
        </button>
      </div>
      <button v-on:click="openCreate" class="button button-success">
        <fa-icon icon="plus"/>
        Criar
      </button>
    </div>
    <div class="table-responsive">
      <datatable
        :columns="table.columns"
        :data="regras"
        class="table table-hover"
      >
        <template slot-scope="{ row }">
          <tr>
            <td class="highlight" style="text-align: center">{{ row.id }}</td>
            <td style="text-align: left">{{ row.descricao }}</td>
            <td style="text-align: center">{{ row.data_inicial | date }}</td>
            <td style="text-align: center">{{ row.data_final | date }}</td>
            <td style="text-align: center">{{ getTipoTraducao(row.tipo) }}</td>
            <td class="actions">
              <button
                type="button"
                class="button button-primary"
                v-on:click="openEdit(row)"
              >
                <fa-icon icon="pencil-alt"/>
              </button>

              <button
                type="button"
                name="button"
                v-on:click="deleteRegra(row.id)"
                class="button button-error"
              >
                <fa-icon icon="trash-alt"/>
              </button>
            </td>
          </tr>
        </template>
      </datatable>
    </div>
    <div style="display: flex; justify-content: center;">
      <pagination
        :data="table.paginator"
        :limit="5"
        @pagination-change-page="loadRegras"
      ></pagination>
    </div>
  </div>
</template>

<script>
import {get, del, put, post} from '@/helpers/apiRequest';
import moment from 'moment';
import Edit from "@/views/RegraNegocio/components/Edit";

const initial = {
  descricao: "",
  data_inicial: moment().format(),
  data_final: moment().add(1, "days").format(),
  perc_max_promocao: 0,
  perc_max_mix: 0,
  tipo: "P",
  produtos: [],
  dimensoes: [],
  promocoesExcessao: [],
  restricoes: [],
  aplicab2b: false,
  aplicacallcenter: false,
  aplicafv: false,
};

export default {
  name: 'listRegraNegocio',
  components: {
    Edit
  },
  data() {
    return {
      loading: false,
      item: {},
      regras: [],
      filtros: {
        data: '',
        termo: '',
      },
      table: {
        columns: [
          {label: 'ID', field: 'id', align: 'center', sortable: false},
          {label: 'Descrição', field: 'descricao', sortable: false},
          {
            label: 'Data Inicial',
            field: 'data_inicial',
            align: 'center',
            sortable: false,
          },
          {
            label: 'Data Final',
            field: 'data_final',
            align: 'center',
            sortable: false,
          },
          {label: 'Tipo', field: 'tipo', align: 'center', sortable: false},
          {label: '', field: '', sortable: false},
        ],
        paginator: {},
      },
    };
  },
  methods: {
    getTipoTraducao(tipo) {
      switch (tipo) {
        case 'P':
          return 'Promoção'
        case 'M':
          return 'Mix de Produto'
        case 'D':
          return 'Dimensão'
        case 'MP':
          return 'Mix com percentual máximo'
        default:
          return 'n/a'
      }
    },
    create(item) {
      this.loading = true;

      post("/admin/regras-negocio", item)
        .then((json) => {
          this.closeEdit();
          this.loadRegras();
          this.$swal({
            title: 'Salvo com sucesso',
            text: json.message,
            showCancelButton: false,
            cancelButtonText: 'Fechar',
            showConfirmButton: true,
            icon: 'success',
          });
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$swal({
            title: "Falha ao cadastrar",
            text: err.message,
            icon: "error",
          });
        });
    },
    update(item) {
      this.loading = true;

      put(`/admin/regras-negocio/${item.id}`, item)
        .then((json) => {
          this.closeEdit();
          this.loadRegras();
          this.$swal({
            title: 'Salvo com sucesso',
            text: json.message,
            showCancelButton: false,
            cancelButtonText: 'Fechar',
            showConfirmButton: true,
            icon: 'success',
          });
        })
        .catch((err) => {
          this.loading = false;
          this.$swal({
            title: "Falha ao cadastrar",
            text: err.message,
            icon: "error",
          });
        });
    },
    deleteRegra(id) {
      this.$swal({
        title: 'Confirma Remover?',
        text: 'Essa ação não poderá ser desfeita',
        showCancelButton: true,
        icon: 'warning',
        confirmButtonText: 'Excluir',
        cancelButtonText: 'Cancelar',
      })
        .then((res) => {
          if (res.value) {
            this.loading = true;
            del(`/admin/regras-negocio/${id}`)
              .then(() => {
                this.cleanFiltros();
                this.loadRegras();
                this.loading = false;
                this.$swal({
                  title: 'Removido com sucesso!',
                  icon: 'success',
                });
              })
              .catch((err) => {
                this.loading = false;
                this.$swal({
                  title: 'Falha ao remover',
                  text: err.message,
                  icon: 'error',
                });
              });
          }
        })
        .catch(() => {
        });
    },
    openCreate() {
      this.item = {...initial};
      this.$modal.show('editRegraNegocio');
    },
    openEdit(regra) {
      this.item = {...regra};
      this.$modal.show('editRegraNegocio');
    },
    closeEdit() {
      this.$modal.hide('editRegraNegocio');
    },
    loadRegras(page = 1) {
      this.loading = true;
      const date = this.getDate();

      get(
        `/admin/regras-negocio?take=12&page=${page}&termo=${
          this.filtros.termo
        }&data=${date}`
      )
        .then((json) => {
          this.regras = json.itens;
          this.table.paginator = json.paginator;
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getDate() {
      if (this.filtros.data) {
        return moment(String(this.filtros.data)).format('YYYY-MM-DD');
      }

      return '';
    },
    cleanFiltros() {
      this.filtros.termo = '';
      this.filtros.data = '';
    },
  },
  beforeMount() {
    this.loadRegras();
  },
};
</script>
