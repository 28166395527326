<template>
  <div class="xrow">
    <div class="col-1-2">
      <div class="form-group">
        <label for="">Descrição:</label>
        <input class="form-control" maxlength="250" v-model="item.descricao"/>
        <div class="message">{{ validation.firstError("item.descricao") }}</div>
      </div>
    </div>
    <div class="col-1-4">
      <div class="form-group">
        <label for="">Data de início:</label>
        <datetime
          inputId="dtIni"
          input-class="form-control"
          type="date"
          v-model="item.data_inicial"
          :max-datetime="item.data_final"
          :phrases="{ok: 'Confirmar', cancel: 'Fechar'}"
          value-zone="local"
          format="d/M/y"
        />
        <div class="message">{{ validation.firstError("item.data_inicial") }}</div>
      </div>
    </div>
    <div class="col-1-4">
      <div class="form-group">
        <label for="">Data de término:</label>
        <datetime :disabled="item.data_final === null"
                  inputId="dtFim"
                  input-class="form-control"
                  type="date"
                  :min-datetime="item.data_inicial"
                  v-model="item.data_final"
                  :phrases="{ok: 'Confirmar', cancel: 'Fechar'}"
                  value-zone="local"
                  format="d/M/y"
        />
        <div class="message">{{ validation.firstError("item.data_final") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'informacao',
  props: ['item', 'validation'],
};
</script>
