<style scoped>
thead {
  font-weight: 700;
  font-size: 80%;
}

thead > tr > th {
  padding-right: 10px;
}

tbody {
  font-size: 80%;
}

tbody > tr > td {
  padding-right: 10px;
  border-bottom: 0px;
}
</style>

<template>
  <table class="w-100">
    <thead>
      <tr class="col-5">
        <th>ID</th>
        <th>% Desconto</th>
        <th>Cliente</th>
        <th>Data inicial</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="highlight">{{ item.id }}</td>
        <td>{{ item.perc_desconto || "n/d" }}</td>
        <td>{{ item.cliente_id || "*" }}</td>
        <td>{{ item.data_inicio }}</td>
      </tr>
    </tbody>

    <thead>
      <tr>
        <th>Status</th>
        <th>Preço promoção</th>
        <th>Filial</th>
        <th>Data final</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{ item.situacao }}</td>
        <td>{{ item.preco_promocional || "n/d" }}</td>
        <td>{{ item.filial_id || "*" }}</td>
        <td>{{ item.data_fim }}</td>
      </tr>
    </tbody>

    <thead>
      <tr>
        <th colspan="5">Descrição</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td colspan="5">{{ item.descricao }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "itemPromocao",
  props: ["item"],
};
</script>
