<template>
  <div>
    <CTabs variant="tabs" class="nav-tabs-boxed">
      <CTab :title="descPlural">
        <List/>
      </CTab>
    </CTabs>
  </div>

</template>
<script>
import {loadDescs} from '@/helpers/utils';
import List from "@/views/RegraNegocio/components/List";

export default {
  name: 'regraNegocio',
  components: {
    List
  },
  data() {
    return {
      descSingular: '',
      descPlural: '',
    };
  },
  methods: {
    changeTab(tabName) {
      this.activeTab = tabName;
    },
  },

  beforeMount() {
    const {descSingular, descPlural} = loadDescs('ADMIN_REGRA_NEGOCIO');
    this.descSingular = descSingular;
    this.descPlural = descPlural;
  },
};
</script>
