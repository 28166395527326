<style scoped>
.regra {
  position: relative;
  border: 2px solid #ddd;
  padding: 12px;
  margin-bottom: 24px;
}

.regra + .regra::before {
  position: absolute;
  text-align: center;
  content: 'Ou a regra abaixo deve ser válida';
  top: -25px;
}

.regra.validar_todas + .regra::before {
  content: 'E a regra abaixo também deve ser válida';
}

.regra-and + .regra-and::before {
  content: 'Ou a regra abaixo deve ser válida';
}

.regra-container {
  display: flex;
  position: relative;
  margin-bottom: 6px;
}

.regra-dimensao {
  position: relative;
  margin-right: 16px;
}

.regra-dimensao + .regra-dimensao::before {
  position: absolute;
  bottom: 5px;
  left: -12px;
  content: 'e';
}

.regra-dimensao label {
  font-size: 12px;
  font-weight: bold;
}

.button-delete-container input {
  width: 50px;
  text-align: right;
}

.button-delete-container {
  overflow: hidden;
  display: block;
}

.button-delete-container button {
  float: right;
}
</style>

<template>
  <div>
    <div class="xrow">
      <div class="col-1-1">
        <div class="form-group">
          <label>Aplicar nos sistemas:</label>
          <label class="checkbox">
            <input type="checkbox" v-model="item.aplicab2b" :value="item.aplicab2b">
            &ensp; B2B
          </label>
          <label class="checkbox">
            <input type="checkbox" v-model="item.aplicacallcenter" :value="item.aplicacallcenter">
            &ensp; Callcenter
          </label>
          <label class="checkbox">
            <input type="checkbox" v-model="item.aplicafv" :value="item.aplicafv">
            &ensp; Força de vendas
          </label>
        </div>
      </div>
    </div>
    <div class="xrow">
      <div class="col-1-2">
        <div class="form-group">
          <label>Tipo de regra:</label>
          <select class="form-control" v-model="item.tipo">
            <option v-for="(tipo, chave) in tipos" :value="chave">{{ tipo.descricao }}
            </option>
          </select>
          <div class="message">{{ validation.firstError('item.tipo') }}</div>
        </div>
      </div>
    </div>
    <!-- REGRA POR PROMOCAO -->
    <div class="xrow" v-if="item.tipo === 'P'">
      <div class="col-1-1">
        <div class="alert alert-info">
          O envio do pedido será vetado caso o pedido tenha mais itens em promoção do que o cadastrado na regra abaixo,
          caso existam 2 ou mais regras cadastradas, certifique-se de o período da proibição não entre em conflito,
          pois as regras são validadas de forma individual.
        </div>
        <div class="col-1-1">
          <div style="display:flex; flex-direction: row; align-items: center; margin-bottom: 20px">
            <span>O pedido pode ter no máximo</span>
            <input-percent style="width: 100px;margin: 0px 10px" class="form-control"
                           v-model="item.perc_max_promocao"/>
            <span> do valor em produtos de promoção.</span>
          </div>
        </div>
        <div class="col-1-1">
          <ListaPromocoes
              :regra="item"
              :promocoes="promocoes"
              :promocoesSelecionadas="item.promocoesExcessao"
              :select-callback="addPromocao"
              :deselect-callback="removePromocao"
          />
        </div>
      </div>
    </div>

    <!-- REGRA POR MIX DE PRODUTOS -->
    <div class="xrow" v-if="item.tipo === 'M'">
 
      <div class="col-1-1">
        <div class="alert alert-info">
          O envio do pedido será vetado caso algum dos itens abaixo estejam fora das quantidades definidas,
          ao vincular vários itens, a regra é validada por completo, ou seja, caso um dos itens seja incluído no pedido,
          todos os demais deverão estar no pedido nas quantidades especificadas.
        </div>
      </div>


      <div class="col-1-1">
        <label>
          <strong>Produtos selecionados:</strong>
          <small class="validation-error">{{ validation.firstError('item.produtos') }}</small>
        </label>
        <ListaProdutos
            :regra="item"
            :produtos="produtos"
            :produtosSelecionados="item.produtos"
            :select-callback="addProduto"
            :deselect-callback="removeProduto"
            :hideQtd="false"
        />
      </div>
    </div>

    <!-- REGRA POR MIX COM PERCENTUAL MAXIMO -->
    <div class="xrow" v-if="item.tipo === 'MP'">
 
      <div class="col-1-1">
        <div class="alert alert-info">
          O envio do pedido será vetado caso a soma dos iten selecionados e adiocionados ao carrinho, seja maior que o percentual máximo estipulado.
        </div>
      </div>


      <div class="col-1-1">
          <div style="display:flex; flex-direction: row; align-items: center; margin-bottom: 20px">
            <span>O pedido pode ter no máximo</span>
            <input-percent style="width: 100px;margin: 0px 10px" class="form-control"
                           v-model="item.perc_max_mix"/>
            <span> do valor em produtos do mix.</span>
          </div>
        </div>


      <div class="col-1-1">
        <label>
          <strong>Produtos selecionados:</strong>
          <small class="validation-error">{{ validation.firstError('item.produtos') }}</small>
        </label>
        <ListaProdutos
            :regra="item"
            :produtos="produtos"
            :produtosSelecionados="item.produtos"
            :select-callback="addProduto"
            :deselect-callback="removeProduto"
            :hide-qtd="true"
        />
      </div>
    </div>

    <!-- REGRA POR DIMENSAO -->
    <div class="xrow" v-if="item.tipo === 'D'">
   
      <div class="col-1-1">
        <div class="alert alert-info">
          O envio do pedido será vetado caso o segmento de venda não tenha um percentual mínimo de outro segmento em
          relação a ele. Exemplo: Vetar o envio de pedido que contenha tubos caso nesse mesmo pedido não tenha 50% do
          valor
          em conexões.
        </div>
      </div>

      <div class="col-1-2">
        <div class="form-group">
          <label>Todas as regras precisam ser válidas?</label>
          <select class="form-control" v-model="item.validar_todas_regras">
            <option :value="1">Sim</option>
            <option :value="0">Não</option>
          </select>
          <div class="message">{{ validation.firstError('item.tipo') }}</div>
        </div>
      </div>

      <div class="col-1-1">
        <div v-for="(dimensaoRegra, indexDimensaoRegra) in item.dimensoes" class="regra" :class="{'validar_todas': item.validar_todas_regras}">
          <h5>Regra de validação</h5>
          <div>
            Ao vender produtos dos segmentos abaixo:
          </div>
          <div class="regra-container">
            <div class="col-1-4">
              <div class="form-group">
                <label>Tipo de segmento</label>
                <select class="form-control" v-model="item.dimensoes[indexDimensaoRegra].coluna"
                        v-on:change="changeColunaSelecionada">
                  <option v-for="(dimensao) in dimensoes" :value="dimensao.coluna">{{ dimensao.label }}</option>
                </select>
              </div>
            </div>
            <div class="col-1-4">
              <div class="form-group">
                <label>Segmento:</label>
                <select class="form-control" v-model="item.dimensoes[indexDimensaoRegra].dimensao_id">
                  <option v-for="(dimensao) in getItensByColunaSelecionada(item.dimensoes[indexDimensaoRegra].coluna)"
                          :value="dimensao.id">{{
                      dimensao.desc
                    }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div
              style="display:flex; flex-direction: row; align-items: center; margin-left: 10px; margin-bottom: 20px">
            <span>O segmento abaixo deverá representar no mínimo</span>
            <input-percent style="width: 100px;margin: 0px 10px" class="form-control"
                           v-model="item.dimensoes[indexDimensaoRegra].perc_min_vinculado"/>
            <span> e no máximo</span>
            <input-percent style="width: 100px;margin: 0px 10px" class="form-control"
                           v-model="item.dimensoes[indexDimensaoRegra].perc_max_vinculado"/>
            <span>do valor:</span>
          </div>
          <div class="regra-container">
            <div class="col-1-4">
              <div class="form-group">
                <label>Tipo de segmento</label>
                <select class="form-control" v-model="item.dimensoes[indexDimensaoRegra].coluna_vinculada"
                        v-on:change="changeColunaVinculadaSelecionada">
                  <option v-for="(dimensao) in dimensoes" :value="dimensao.coluna">{{
                      dimensao.label
                    }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-1-4">
              <div class="form-group">
                <label>Segmento:</label>
                <select class="form-control" v-model="item.dimensoes[indexDimensaoRegra].dimensao_vinculada_id">
                  <option
                      v-for="(dimensao) in getItensByColunaSelecionada(item.dimensoes[indexDimensaoRegra].coluna_vinculada)"
                      :value="dimensao.id">
                    {{ dimensao.desc }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="button-delete-container">
            <button
                type="button"
                class="button button-error"
                v-on:click="excluirRegra(indexDimensaoRegra)"
            >
              <fa-icon icon="trash-alt"/>
            </button>
          </div>
        </div>
        <button
              type="button"
              class="button button-primary"
              v-on:click="adicionarRegra"
          >
          Adicionar Regra
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ListaProdutos from './ListaProdutos';
import ListaPromocoes from './ListaPromocoes';
import _remove from 'lodash/remove';
import _filter from 'lodash/filter';

const initialDimensao = () => ({
  dimensao_id: null,
  coluna: null,
  dimensao_vinculada_id: null,
  coluna_vinculada: null,
  perc_min_vinculado: 0,
  perc_max_vinculado: 100,
  validar_todas_regras: 1
});

export default {
  name: 'Regra',
  props: [
    'item',
    'produtos',
    'dimensoes',
    'promocoes',
    'validation',
  ],
  data() {
    return {
      colunaSelecionada: null,
      colunaVinculadaSelecionada: null,
      tipos: {
        P: {descricao: 'Promoção'},
        M: {descricao: 'Mix de Produto'},
        MP: {descricao: 'Mix com percentual máximo'},
        D: {descricao: 'Dimensão'},
      },
    };
  },
  components: {
    ListaProdutos,
    ListaPromocoes
  },
  watch: {
    'item.tipo'() {
      this.item.produtos = [];
      this.item.dimensoes = [];
      this.item.promocoesExcessao = [];
      this.item.perc_max_promocao = 0;
      this.item.perc_max_mix = 0;
      this.item.validar_todas_regras = 1;
    },
  },
  methods: {
    getItensByColunaSelecionada(coluna) {
      const itensDimensao = _filter(this.dimensoes, (dimensao) => {
        return +dimensao.coluna === +coluna;
      });

      return itensDimensao[0]?.dimensoes;
    },
    changeColunaSelecionada(e) {
      this.colunaSelecionada = e.target.value;
    },
    changeColunaVinculadaSelecionada(e) {
      this.colunaVinculadaSelecionada = e.target.value;
    },
    adicionarRegra() {
      this.item.dimensoes.push(initialDimensao());
    },
    excluirRegra(index) {
      this.item.dimensoes.splice(index, 1);
    },
    addProduto(produto) {
      this.item.produtos.push(produto);
    },
    removeProduto(id) {
      const produtos = [...this.item.produtos];
      _remove(produtos, (produto) => produto.produto_id === id);
      this.item.produtos = produtos;
    },
    addPromocao(promocao) {
      this.item.promocoesExcessao.push(promocao);
    },
    removePromocao(id) {
      const promocoes = [...this.item.promocoesExcessao];
      _remove(promocoes, (promocao) => promocao.promocao_id === id);
      this.item.promocoesExcessao = promocoes;
    },
  }
};
</script>
