<style scoped>
.table tbody > tr:nth-child(even) td {
  background-color: #fff;
}

.table tbody > tr > td {
  padding: 6px;
}

.list {
  padding: 10px 0;
  height: 200px;
  overflow-y: auto;
}

.list-item {
  font-size: 14px;
  padding: 8px;
  width: 100%;
  position: relative;
}

.list-item {
  font-size: 14px;
  padding: 8px;
  width: 100%;
  position: relative;
}

.list-item:nth-child(odd) {
  background-color: #f1f1f1;
}

.list-item:nth-child(even) {
  font-size: 14px;
}

.list-item:nth-child(even) {
  font-size: 14px;
}

.add-item {
  padding: 1px 5px;
}

.item-promocao {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 90%;
}

table > tr {
  display: flex;
  flex-direction: column;
}

table > td {
  border-bottom: 0px;
}

.title {
  font-weight: 700;
  font-size: 80%;
}

.subtitle {
  font-size: 80%;
}
</style>

<template>
  <div class="row">
    <div class="col-1-2">
      <label>
        <strong>Selecione as promoções que serão desconsideradas:</strong>
      </label>
      <div class="search-select">
        <div class="d-flex">
          <input
            class="form-control"
            placeholder="Pesquisar por ID ou Descrição"
            type="text"
            v-model="searchQuery"
          />
          <button
            class="button button-success ml-2"
            title="Adicionar"
            v-on:click="() => addAllItems()"
          >
            <fa-icon icon="plus" />
          </button>
        </div>
        <label>
          <strong>
            Exibindo {{ filteredItems.length }} de
            {{ promocoes.length - promocoesSelecionadas.length }}
          </strong>
        </label>
        <ul class="list">
          <li class="list-item" v-for="item in filteredItems">
            <div style="display: flex; align-items: center" class="">
              <ItemPromocao :item="item" />
              <div>
                <button
                  class="button button-success"
                  title="Adicionar"
                  v-on:click="() => selectItem(item)"
                >
                  <fa-icon icon="plus" />
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-1-2">
      <label>
        <strong>As seguintes promoções serão desconsideradas:</strong>
      </label>
      <div class="search-select">
        <div class="d-flex">
          <input
            class="form-control"
            placeholder="Pesquisar por ID ou Descrição"
            type="text"
            v-model="searchQueryFiltered"
          />
          <button
            type="button"
            name="button"
            v-on:click="() => removeAllItems()"
            class="button button-error ml-2"
          >
            <fa-icon icon="trash-alt" />
          </button>
        </div>
        <label>
          <strong>
            Exibindo {{ filteredPromocoesSelecionadas.length }} de
            {{ promocoesSelecionadas.length }}
          </strong>
        </label>
        <ul class="list">
          <li
            v-if="promocoesSelecionadas.length === 0"
            class="list-item text-center"
          >
            Nenhuma promoção a ser desconsiderada
          </li>
          <li
            class="list-item"
            v-for="(item, index) in filteredPromocoesSelecionadas"
          >
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
              <ItemPromocao :item="item" />
              <button
                class="button button-error"
                title="Remover"
                v-on:click="() => deselectItem(item, index)"
              >
                <fa-icon icon="trash-alt" />
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import _filter from "lodash/filter";
import _some from "lodash/some";
import { isNumeric, normalizeString } from "@/helpers/utils";
import ItemPromocao from "@/views/RegraNegocio/components/components/Regra/ItemPromocao";

export default {
  name: "multiselectable",
  components: {
    ItemPromocao,
  },
  props: [
    "regra",
    "promocoes",
    "promocoesSelecionadas",
    "selectCallback",
    "deselectCallback",
  ],
  data() {
    return {
      searchQuery: "",
      searchQueryFiltered: "",
      selectedItems: [],
    };
  },
  computed: {
    filteredItems() {
      if (this.searchQuery === "") {
        return this.diffItems(this.promocoes);
      }

      let searchItems = [];

      if (!isNumeric(this.searchQuery)) {
        searchItems = _filter(this.promocoes, (item) => {
          return normalizeString(item.descricao.toLowerCase()).includes(
            normalizeString(this.searchQuery.toLowerCase())
          );
        });
        return this.diffItems(searchItems);
      }

      if (isNumeric(this.searchQuery)) {
        searchItems = _filter(this.promocoes, (item) => {
          return `${item.id}`.startsWith(`${this.searchQuery}`);
        });
        return this.diffItems(searchItems);
      }
      return this.diffItems(searchItems);
    },
    filteredPromocoesSelecionadas() {
      var promocoesSelecionadas = _filter(this.promocoes, (item) => {
        return _some(
          this.promocoesSelecionadas,
          (selected) => `${selected.promocao_id}` === `${item.id}`
        );
      }).slice(0, 15);

      if (this.searchQueryFiltered === "") {
        return promocoesSelecionadas;
      }

      if (isNumeric(this.searchQueryFiltered)) {
        return promocoesSelecionadas.filter((item) => {
          return `${item.id}`.startsWith(`${this.searchQueryFiltered}`);
        });
      }

      if (!!this.searchQueryFiltered && !isNumeric(this.searchQueryFiltered)) {
        return promocoesSelecionadas.filter((item) => {
          return normalizeString(item.descricao.toLowerCase()).includes(
            normalizeString(this.searchQueryFiltered.toLowerCase())
          );
        });
      }
    },
  },
  methods: {
    selectItem(item) {
      this.selectedItems.push({ ...item, promocao_id: item.id });
      this.selectCallback({ ...item, promocao_id: item.id });
    },
    deselectItem(item, index) {
      this.selectedItems.splice(index, 1);
      this.deselectCallback(item["id"]);
    },
    diffItems(items) {
      return _filter(items, (item) => {
        return !_some(
          this.promocoesSelecionadas,
          (selected) => `${selected.promocao_id}` === `${item.id}`
        );
      }).slice(0, 15);
    },
    reloadItems() {
      this.selectedItems = _filter(this.promocoesSelecionadas, (item) => {
        return _some(
          this.promocoesSelecionadas,
          (selected) => `${selected.promocao_id}` === `${item.id}`
        );
      }).slice(0, 15);
    },
    removeAllItems() {
      this.promocoesSelecionadas
        .forEach((item) => {
          this.deselectCallback(item["promocao_id"]);
        })
        .slice(0, 15);

      this.selectedItems = [];
    },
    addAllItems() {
      this.diffItems(this.promocoes).forEach((item) => {
        this.selectCallback({ ...item, promocao_id: item.id });
        this.selectedItems.push({ ...item, promocao_id: item.id });
      });
    },
  },
};
</script>
